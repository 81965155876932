<template>
  <el-form
    ref="form"
    :model="form"
    :rules="rules"
    label-width="100px"
    label-position="left"
  >
    <el-form-item label="id">
      <el-input v-model="form.id" disabled></el-input>
    </el-form-item>

    <el-form-item label="标题" prop="title">
      <el-input v-model="form.title"></el-input>
    </el-form-item>

    <el-form-item label="类型" prop="type">
      <el-select v-model="form.type" placeholder="请选择文章类型">
        <el-option label="日常杂记" value="0"></el-option>
        <el-option label="技术博文" value="1"></el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="是否私密">
      <el-switch v-model="form.privacy"></el-switch>
    </el-form-item>

    <el-form-item label="密码" v-if="form.privacy" prop="password">
      <el-input v-model="form.password"></el-input>
    </el-form-item>

    <Editor />

    <el-form-item>
      <el-button type="primary" @click="onSubmit">立即创建</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import Editor from "../editor.vue";

export default {
  props: ["initForm"],
  components: { Editor },
  data() {
    return {
      form: {
        id: "",
        title: "",
        type: "",
        privacy: false,
        password: "",
      },
      rules: {
        title: [
          { required: true, message: "请输入文章名称", trigger: "blur" },
          { min: 0, message: "长度在至少大于0", trigger: "blur" },
        ],
        type: [
          { required: true, message: "请选择文章类型", trigger: "change" },
        ],
        password: [
          { required: true, message: "请输入文章密码", trigger: "blur" },
          { min: 3, message: "长度在至少大于3", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    if (this.$store.getters.editorContent !== "") {
      this.form = this.$store.getters.editForm;
    }
  },
  beforeDestroy() {},
  methods: {
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid && this.$store.getters.editorContent.length > 0) {
          if (this.form.privacy) {
            this.form.alone = 1;
          } else {
            this.form.alone = 0;
          }
          delete this.form.privacy;
          this.form.insertTime = new Date();
          this.form.content = this.$store.getters.editorContent;
          this.form.size = this.form.content.length;

          let that = this;
          this.$axios
            .post("/adminApi/addArticle", this.form)
            .then(function (resp) {
              if (resp.data.code === 200) {
                that.$notify.success({
                  title: resp.data.msg,
                });
                that.form={};
                that.$store.commit("editorContent","");
                location.reload();
              }
            });
        } else {
          this.$notify({
            title: "警告",
            message: "请检查元素是否填写完整！",
            type: "warning",
          });
        }
      });
    },
  },
};
</script>

<style>
.el-select-dropdown {
  z-index: 10005 !important;
}
</style>