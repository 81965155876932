<template>
  <div>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="id" label="id"> </el-table-column>
      <el-table-column prop="title" label="标题" width="130"> </el-table-column>
      <el-table-column prop="type" label="0日常/1技术" width="100">
      </el-table-column>
      <el-table-column prop="size" label="字数"> </el-table-column>
      <el-table-column prop="insertTime" label="更新时间" width="150">
      </el-table-column>
      <el-table-column prop="niceNum" label="点赞数"> </el-table-column>
      <el-table-column prop="viewNum" label="阅读数"> </el-table-column>
      <el-table-column prop="alone" label="是否私密" width="125">
      </el-table-column>
      <el-table-column prop="password" label="密码"> </el-table-column>

      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button @click="edit(scope)" type="text" size="small"
            >编辑</el-button
          >
          <el-button @click="del(scope)" type="text" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-drawer
      title="文章编辑"
      :visible.sync="drawer"
      direction="ttb"
      size="100%"
    >
      <div style="padding: 30px">
        <NewArcitle :key="key" />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import NewArcitle from "./newArticle.vue";

export default {
  components: { NewArcitle },
  created() {
    this.getData();
  },
  data() {
    return {
      key: 1,
      drawer: false,
      tableData: [
        {
          id: "2",
          title: "未闻花名",
          type: 0,
          size: 1235,
          insertTime: "2021/10/29 10:02:12",
          niceNum: 981,
          viewNum: 2335,
          alone: 1,
          password: "000000",
        },
      ],
    };
  },
  methods: {
    getData() {
      let that = this;
      this.$axios.get("/adminApi/articles").then(function (resp) {
        resp.data.data.forEach((element) => {
          element.privacy = element.alone === 1;
          element.insertTime = that.common.dateFormatter(element.insertTime);
        });
        that.tableData = resp.data.data;
      });
    },
    async edit(scope) {
      //模拟 请求 获取值
      this.$store.commit("editorContent", "防止编辑为空");
      let that = this;
      this.$axios
        .get("/adminApi/getArticle?id=" + scope.row.id)
        .then(function (resp) {
          resp.data.data.privacy = resp.data.data.alone === 1;
          that.$store.commit("editForm", resp.data.data);
          that.$store.commit("editorContent", resp.data.data.content);
          //渲染子组件 编辑页面
          that.key = that.key + 1;
          that.drawer = true;
        });
    },
    del(scope) {
      this.$confirm("是否确定删除文章：" + scope.row.title, "确认信息", {
        distinguishCancelAndClose: true,
        confirmButtonText: "删除",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.$message({
            type: "info",
            message: "已删除！",
          });
          let that = this;
          this.$axios
            .get("/adminApi/delArticle?id=" + scope.row.id)
            .then(function () {
              that.getData();
            });
        })
        .catch(() => {});
    },
  },
  beforeDestroy() {
    this.$store.commit("editorContent", "");
  },
};
</script>

<style scoped>
</style>